import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import IThreadGroupAccount from "~/Interfaces/Message/IThreadGroupAccount";
import MyAccounts from "~/store/MyAccounts";
import dayjs from "dayjs";
import IThreadGroup from "../Interfaces/Message/IThreadGroup";

@Module({ stateFactory: true, namespaced: true, name: 'ThreadGroup'})
export default class ThreadGroup extends VuexModule {
  threadGroup: IThreadGroup|undefined = undefined

  @Mutation
  setThreadGroup(threadGroup: IThreadGroup)
  {
    this.threadGroup = threadGroup;
  }

  get getThreadGroup(): IThreadGroup|undefined
  {
    return this.threadGroup
  }

  get getThreadGroupAccountSelf(): IThreadGroupAccount|null
  {
    if(this.threadGroup === undefined) {
      return null
    }

    const index = this.threadGroup.threadGroupAccounts.findIndex((threadGroupAccount: IThreadGroupAccount) => {
      return threadGroupAccount.isSelf
    })

    return this.threadGroup.threadGroupAccounts[index]
  }

  get getThreadGroupAccountOther(): IThreadGroupAccount|null
  {
    if(this.threadGroup === undefined) {
      return null
    }

    const index = this.threadGroup.threadGroupAccounts.findIndex((threadGroupAccount: IThreadGroupAccount) => {
      return !threadGroupAccount.isSelf
    })

    return this.threadGroup.threadGroupAccounts[index]
  }

  get getNameOther(): string|null
  {
    const account = this.getThreadGroupAccountOther
    if(account === null) {
      return ''
    }

    if(account.team) {
      return account.team.teamName
    }
    if(account.connectClient) {
      return account.connectClient.connectClientCompanyName
    }
    if(account.sa) {
      return account.sa.saName
    }
    if(account.company) {
      return account.company.companyName
    }

    return ''
  }

  get getNameSelf(): string|null
  {
    const account = this.getThreadGroupAccountSelf
    if(account === null) {
      return ''
    }

    if(account.team) {
      return account.team.teamName
    }
    if(account.connectClient) {
      return account.connectClient.connectClientCompanyName
    }
    if(account.sa) {
      return account.sa.saName
    }
    if(account.company) {
      return account.company.companyName
    }

    return ''
  }

  get getImageOther(): string|null
  {
    const account = this.getThreadGroupAccountOther
    if(account === null) {
      return null
    }

    if(MyAccounts.isIConnectClient(account.connectClient)) {
      return null
    }

    if(MyAccounts.isITeam(account.team)) {
      return  account.team.teamImage === '' ? null : account.team.teamImage
    }

    if(MyAccounts.isISa(account.sa)) {
      return  account.sa.saImage === '' ? null : account.sa.saImage
    }

    if(MyAccounts.isICompany(account.company)) {
      return  account.company.companyImage === '' ? null : account.company.companyImage
    }

    return null
  }

  get getFoundationDate(): string|null
  {
    const account = this.getThreadGroupAccountOther
    if(account === null) {
      return null
    }

    if(account.team) {
      if(account.team.teamFoundationDate !== null) {
        return dayjs(account.team.teamFoundationDate).format('YYYY/MM/DD')
      }
    }
    if(account.company) {
      if(account.company.companyFoundedAt !== null) {
        return dayjs(account.company.companyFoundedAt).format('YYYY/MM/DD')
      }
    }
    if(account.connectClient) {
      return null
    }

    return null
  }

  get getEmployeesSize(): string|null
  {
    const account = this.getThreadGroupAccountOther
    if(account === null) {
      return null
    }

    if(account.team) {
      if(account.team.teamActivePerson !== null) {
        return account.team.teamActivePerson + '人'
      }
    }
    if(account.company) {
      if(account.company.companyCountWorker !== null) {
        return account.company.companyCountWorker + '人'
      }
    }
    if(account.connectClient) {
      if(account.connectClient.connectClientEmployeesSize !== null) {
        return account.connectClient.connectClientEmployeesSize
      }
    }

    return null
  }

  get isConnect(): boolean
  {
    if(!this.threadGroup?.haveConnect) {
      return false;
    }

    const account = this.getThreadGroupAccountOther
    if(account === null) {
      return false
    }

    if(MyAccounts.isIConnectClient(account.connectClient)) {
      return true
    }

    if(MyAccounts.isITeam(account.team)) {
      return true
    }

    if(MyAccounts.isISa(account.sa)) {
      return false
    }

    if(MyAccounts.isICompany(account.company)) {
      return false
    }

    return false
  }

  get isKakutoku(): boolean
  {
    if(!this.threadGroup?.haveKakutoku) {
      return false;
    }

    const account = this.getThreadGroupAccountOther
    if(account === null) {
      return false
    }

    if(MyAccounts.isIConnectClient(account.connectClient)) {
      return false
    }

    if(MyAccounts.isITeam(account.team)) {
      return true
    }

    if(MyAccounts.isISa(account.sa)) {
      return true
    }

    if(MyAccounts.isICompany(account.company)) {
      return true
    }

    return false
  }
}
