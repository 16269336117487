import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

export interface ISnackbar {
  name: string,
  is_display: boolean
}

@Module({ stateFactory: true, namespaced: true, name: 'Snackbar'})
export default class Snackbar extends VuexModule {
  snackbars: ISnackbar[] = []

  @Mutation
  show(name: string)
  {
    let index = this.snackbars.findIndex(snackbar => snackbar.name === name);
    if(index < 0) {
      this.snackbars.push({name: name, is_display: true})

      return
    }

    this.snackbars[index].is_display = true;
  }

  @Mutation
  hide(name: string)
  {
    let index = this.snackbars.findIndex(snackbar => snackbar.name === name);
    if(index === undefined) {
      return;
    }

    this.snackbars[index].is_display = false;
  }

  get shouldDisplay() {
    var self = this;

    return (name: string) => {
      let snackbar = self.snackbars.find(snackbar => snackbar.name === name);

      return snackbar?.is_display ?? false
    }
  }
}
