







import {Component, Vue} from "nuxt-property-decorator";
import {IsLoggedInStore} from "~/utils/store-accessor";

@Component
export default class Default extends Vue {

  constructor() {
    super();
  }

  async fetch()
  {
    let is_logged_in = IsLoggedInStore.get

    let error = false
    if(!is_logged_in) {
      await this.$services.login.login()
        .then((result) => {
          is_logged_in = result
        }).catch(reason => {
          if (reason.response) {
            console.log(reason.response)
          } else if (reason.request) {
            console.log(reason.request)
          } else {
            console.log(reason)
            if(reason.message === 'EmptyTokens') {
              // @MEMO ログアウトさせるため
              return
            }
          }
          console.log(reason)
          error = true
        })

      IsLoggedInStore.set(is_logged_in)
    }

    if(error) {
      this.$router.push('/login_error')
    } else if(!is_logged_in) {
      this.$router.push('/logout')
    }
  }
}
