import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import IThreadDetail from "../Interfaces/Message/IThreadDetail";

@Module({ stateFactory: true, namespaced: true, name: 'ThreadDetails'})
export default class ThreadDetails extends VuexModule {
  details: IThreadDetail[] = []

  @Mutation
  setThreadDetails(details: IThreadDetail[]|undefined)
  {
    if(details === undefined) {
      return
    }

    this.details = details;
  }

  get getThreadDetails(): IThreadDetail[] {
    return this.details;
  }
}
