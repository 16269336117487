import {NuxtAxiosInstance} from "@nuxtjs/axios";
import IProduct from "~/Interfaces/IProduct";

export default class ClientProductService {
  private readonly axios: NuxtAxiosInstance;

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * 一覧を取得
   */
  async get(): Promise<IProduct[]|undefined>
  {
    try {
      let response = await this.axios.get('api/client/product/get')

      // console.log(response)

      return JSON.parse(response.data);
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * 1件取得する
   * @param product_hash_id
   */
  async find(product_hash_id: string): Promise<IProduct|undefined>
  {
    try {
      let response = await this.axios.get('api/client/product/' + product_hash_id)

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      console.log(err)
    }
  }
}
