import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'
import { LockStore } from '~/store'
import IMessageBridge from '~/Interfaces/Message/IMessageBridge'

export default class LoginService {
  private readonly axios: NuxtAxiosInstance
  private readonly config: NuxtRuntimeConfig

  constructor(axios: NuxtAxiosInstance, config: NuxtRuntimeConfig) {
    this.axios = axios
    this.config = config
  }

  /**
   * Connectの代行会社のtokenを取得する
   */
  async connectCompany(): Promise<IMessageBridge | undefined> {
    try {
      const response = await this.axios.get(
        this.config.APP_URL_CONNECT + 'api/message_bridge/company',
        {
          withCredentials: true,
        }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)

      return undefined
    }
  }

  /**
   * Connectの利用企業のtokenを取得する
   */
  async connectClient(): Promise<IMessageBridge | undefined> {
    try {
      const response = await this.axios.get(
        this.config.APP_URL_CONNECT + 'api/message_bridge/client',
        {
          withCredentials: true,
        }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    }
  }

  /**
   * kakutokuの代行会社のtokenを取得する
   */
  async kakutoku(): Promise<IMessageBridge | undefined> {
    try {
      const response = await this.axios.get(
        this.config.APP_URL_KAKUTOKU + 'api/message_bridge/kakutoku',
        {
          withCredentials: true,
        }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    }
  }

  /**
   * cloud企業のtokenを取得する
   */
  async cloud(): Promise<IMessageBridge | undefined> {
    try {
      const response = await this.axios.get(
        this.config.APP_URL_CLOUD + 'api/message_bridge/cloud',
        {
          withCredentials: true,
        }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return undefined
    }
  }

  /**
   * ログインする
   * @param tokens
   */
  async setMessageLogin(tokens: IMessageBridge[]): Promise<boolean> {
    LockStore.lock(LockStore.types.login)
    try {
      const response = await this.axios.post('api/message_login', { tokens })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.login)
    }
  }

  async login(): Promise<boolean> {
    const tokens: IMessageBridge[] = []

    const promises: Promise<unknown>[] = []

    const connectCompanyPromise = new Promise((resolve) => {
      const token = this.connectCompany()
      resolve(token)
    })
    promises.push(connectCompanyPromise)

    const connectClientPromise = new Promise((resolve) => {
      const token = this.connectClient()
      resolve(token)
    })
    promises.push(connectClientPromise)

    if (this.config.SHOULD_LOGIN_KAKUTOKU_AND_CLOUD) {
      const kakutokuPromise = new Promise((resolve) => {
        const token = this.kakutoku()
        resolve(token)
      })
      promises.push(kakutokuPromise)

      const cloudPromise = new Promise((resolve) => {
        const token = this.cloud()
        resolve(token)
      })
      promises.push(cloudPromise)
    }

    await Promise.all(promises).then((resultTokens) => {
      resultTokens.map((token) => {
        if (token) {
          tokens.push(<IMessageBridge>token)
        }
      })
    })

    console.log(tokens)

    if (!tokens.find((token) => token.token !== '')) {
      throw new Error('EmptyTokens')
    }

    let login_result = false
    if (tokens.length > 0) {
      login_result = await this.setMessageLogin(tokens)
    }

    return login_result
  }
}
