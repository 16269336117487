import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import IThreadGroup from "../Interfaces/Message/IThreadGroup";

@Module({ stateFactory: true, namespaced: true, name: 'ThreadGroups'})
export default class ThreadGroups extends VuexModule {
  thread_groups: IThreadGroup[] = []

  @Mutation
  setThreadGroups(groups: IThreadGroup[]|undefined)
  {
    if(groups === undefined) {
      return
    }

    this.thread_groups = groups;
  }

  get getThreadGroups(): IThreadGroup[] {
    return this.thread_groups;
  }
}
