import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import IRead from "../Interfaces/Message/IRead";

@Module({ stateFactory: true, namespaced: true, name: 'Reads'})
export default class Reads extends VuexModule {
  reads: IRead[] = []

  @Mutation
  setReads(reads: IRead[]|undefined)
  {
    if(reads === undefined) {
      return
    }

    this.reads = reads;
  }

  @Mutation
  setIsRead(thread_hash_id: string)
  {
    let read = this.reads.find(read => read.threadHashId === thread_hash_id);
    if(read === undefined) {
      return
    }

    read.isRead = true
  }

  get getReads(): IRead[]
  {
    return this.reads
  }

  get isReadByThreadGroupId()
  {
    var self = this;

    return (thread_group_id: number): boolean => {
      const count = self.reads.filter((read: IRead) => {
        return read.threadGroupId === thread_group_id && !read.isRead
      }).length

      return count === 0
    }
  }

  get isReadByThreadHashId()
  {
    var self = this;

    return (thread_hash_id: string): boolean => {
      let read = self.reads.find(read => read.threadHashId === thread_hash_id);

      if(read === undefined) {
        return false
      }

      return read.isRead
    }
  }

  get isReadBySourceGroupHashId()
  {
    const self = this;

    return (sourceGroupHashId: string|undefined): boolean => {
      if(!sourceGroupHashId) {
        return false
      }

      const count = self.reads.filter((read: IRead) => {
        return read.sourceGroupHashId === sourceGroupHashId && !read.isRead
      }).length

      return count === 0
    }
  }
}
