import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import ITopic from "../Interfaces/Message/ITopic";
import IThreadGroupAccount from "~/Interfaces/Message/IThreadGroupAccount";
import dayjs from "dayjs";
import MyAccounts from "~/store/MyAccounts";

@Module({ stateFactory: true, namespaced: true, name: 'Topic'})
export default class Topic extends VuexModule {
  topic: ITopic|undefined = undefined

  @Mutation
  setTopic(topic: ITopic|undefined|null)
  {
    if(topic === undefined) {
      return
    }

    if(topic === null) {
      this.topic = undefined
      return
    }

    this.topic = topic;
  }

  get getTopic(): ITopic|undefined
  {
    return this.topic
  }

  get getThreadGroupAccountSelf(): IThreadGroupAccount|null
  {
    if(this.topic === undefined) {
      return null
    }

    const index = this.topic.group.threadGroupAccounts.findIndex((threadGroupAccount: IThreadGroupAccount) => {
      return threadGroupAccount.isSelf
    })

    return this.topic.group.threadGroupAccounts[index]
  }

  get getThreadGroupAccountOther(): IThreadGroupAccount|null
  {
    if(this.topic === undefined) {
      return null
    }

    const index = this.topic.group.threadGroupAccounts.findIndex((threadGroupAccount: IThreadGroupAccount) => {
      return !threadGroupAccount.isSelf
    })

    return this.topic.group.threadGroupAccounts[index]
  }

  get getNameOther(): string|null
  {
    const account = this.getThreadGroupAccountOther
    if(account === null) {
      return ''
    }

    if(account.team) {
      return account.team.teamName
    }
    if(account.connectClient) {
      return account.connectClient.connectClientCompanyName
    }
    if(account.sa) {
      return account.sa.saName
    }
    if(account.company) {
      return account.company.companyName
    }

    return ''
  }

  get getNameSelf(): string|null
  {
    const account = this.getThreadGroupAccountSelf
    if(account === null) {
      return ''
    }

    if(account.team) {
      return account.team.teamName
    }
    if(account.connectClient) {
      return account.connectClient.connectClientCompanyName
    }
    if(account.sa) {
      return account.sa.saName
    }
    if(account.company) {
      return account.company.companyName
    }

    return ''
  }

  get getImageOther(): string|null
  {
    const account = this.getThreadGroupAccountOther
    if(account === null) {
      return null
    }

    if(MyAccounts.isIConnectClient(account.connectClient)) {
      return null
    }

    if(MyAccounts.isITeam(account.team)) {
      return  account.team.teamImage === '' ? null : account.team.teamImage
    }

    if(MyAccounts.isISa(account.sa)) {
      return  account.sa.saImage === '' ? null : account.sa.saImage
    }

    if(MyAccounts.isICompany(account.company)) {
      return  account.company.companyImage === '' ? null : account.company.companyImage
    }

    return null
  }

  get getFoundationDate(): string|null
  {
    const account = this.getThreadGroupAccountOther
    if(account === null) {
      return null
    }

    if(account.team) {
      if(account.team.teamFoundationDate !== null) {
        return dayjs(account.team.teamFoundationDate).format('YYYY/MM/DD')
      }
    }
    if(account.company) {
      if(account.company.companyFoundedAt !== null) {
        return dayjs(account.company.companyFoundedAt).format('YYYY/MM/DD')
      }
    }
    if(account.connectClient) {
      return null
    }

    return null
  }

  get getEmployeesSize(): string|null
  {
    const account = this.getThreadGroupAccountOther
    if(account === null) {
      return null
    }

    if(account.team) {
      if(account.team.teamActivePerson !== null) {
        return account.team.teamActivePerson + '人'
      }
    }
    if(account.company) {
      if(account.company.companyCountWorker !== null) {
        return account.company.companyCountWorker + '人'
      }
    }
    if(account.connectClient) {
      if(this.topic?.thread.connectProductForProfile?.connectProductEmployees == undefined) {
        return '非公開'
      }
      return this.topic?.thread.connectProductForProfile?.connectProductEmployees.toString()
    }

    return null
  }

  get isConnectClientSelf(): boolean
  {
    const account = this.getThreadGroupAccountSelf
    if(account === null) {
      return false
    }

    if(account.accountTypeSelf ==='connect_client') {
      return true
    }

    return false
  }

  get isConnectCompanySelf(): boolean
  {
    const account = this.getThreadGroupAccountSelf
    if(account === null) {
      return false
    }

    console.log(account)
    if(account.accountTypeSelf ==='connect_company') {
      return true
    }

    return false
  }

  get isTeamSelf(): boolean
  {
    const account = this.getThreadGroupAccountSelf
    if(account === null) {
      return false
    }

    if(account.accountTypeSelf === 'team') {
      return true
    }

    return false
  }
  get isTeamOther(): boolean
  {
    const account = this.getThreadGroupAccountOther
    if(account === null) {
      return false
    }

    if(account.accountTypeSelf === 'team') {
      return true
    }

    return false
  }

  get isSaSelf(): boolean
  {
    const account = this.getThreadGroupAccountSelf
    if(account === null) {
      return false
    }

    if(account.accountTypeSelf === 'sa') {
      return true
    }

    return false
  }

  get isCompanySelf(): boolean
  {
    const account = this.getThreadGroupAccountSelf
    if(account === null) {
      return false
    }

    if(account.accountTypeSelf === 'company') {
      return true
    }

    return false
  }

  get isConnect(): boolean
  {
    if(this.getTopic === undefined) {
      return false
    }

    return this.getTopic?.thread.threadFlags.threadIsConnect
  }

  get isKakutoku(): boolean
  {
    if(this.getTopic === undefined) {
      return false
    }

    return this.getTopic?.thread.threadFlags.threadIsKakutoku
  }

  get isScout(): boolean
  {
    if(this.getTopic === undefined) {
      return false
    }

    return this.getTopic?.thread.threadFlags.threadIsScout
  }
}
