// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".header_2rXVS{display:flex;align-items:center;justify-content:center;height:60px;width:100%}.header_2rXVS .logo_sp_7Lsxl{display:none}.header_2rXVS.sp_3GjE_{height:46px}.header_2rXVS.sp_3GjE_ .logo_1hNT-{display:none}.header_2rXVS.sp_3GjE_ .logo_sp_7Lsxl{display:block}", ""]);
// Exports
exports.locals = {
	"header": "header_2rXVS",
	"logo_sp": "logo_sp_7Lsxl",
	"sp": "sp_3GjE_",
	"logo": "logo_1hNT-"
};
module.exports = exports;
