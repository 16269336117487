import { NuxtAxiosInstance } from '@nuxtjs/axios'
import ICompany from '~/Interfaces/ICompany'
import MyAccounts, { IMyAccount } from '~/store/MyAccounts'

export default class ConnectCompanyService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * 注意事項に同意する
   *
   * @param companyHashId
   */
  async postThreadAttention(
    companyHashId: string
  ): Promise<ICompany | undefined> {
    try {
      const response = await this.axios.post(
        'api/connect/company/thread_attention/' + companyHashId
      )

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    }
  }

  shouldShowThreadAttentionModal(myAccounts: IMyAccount[]): boolean {
    const connectCompanyAccount = myAccounts.find((myAccount) =>
      MyAccounts.isICompany(myAccount.account)
    )
    if (!connectCompanyAccount || !connectCompanyAccount.account) return false

    const connectCompany = (connectCompanyAccount.account as any) as ICompany

    return !connectCompany.isConfirmThreadAttention
  }
}
