import { Vue } from 'nuxt-property-decorator'
import dayjs from 'dayjs'

export default (context) => {
  const uuid = makeUUID()
  const scriptLoadedAt = dayjs().locale('Asia/Tokyo').format()
  let errorCount = 0
  let errorOccurredAt: Date

  context.$axios.onError((error) => {
    report('axios', {
      error,
      response: {
        status: error.response.status,
        statusText: error.response.statusText,
        responseData: error.response.data,
      },
    })
  })
  Vue.config.errorHandler = (error, vm, info) => {
    report('Vue.config.errorHandle', {
      message: error.message,
      name: error.name,
      tag: vm.$vnode?.componentOptions?.tag,
      properties: getProperties(vm),
      stack: error.stack,
    })
  }

  function getProperties(vm: Vue) {
    // @ts-ignore
    // eslint-disable-next-line no-proto
    return Object.keys(vm.__proto__)
      .map((key) => {
        if (key === 'constructor') {
          return undefined
        }
        // @ts-ignore
        return { key, value: vm[key] }
      })
      .filter(Boolean)
  }

  window.addEventListener('error', (event) => {
    report('EventListener', {
      message: event.message,
      filename: event.filename,
      lineno: event.lineno,
      colno: event.colno,
      error: {
        message: event.error.message,
        name: event.error.name,
        stack: event.error.stack,
      },
    })
  })

  window.addEventListener('unhandledrejection', (event) => {
    report('unhandledrejection EventListener', event.reason)
  })

  /**
   * @param type
   * @param err
   */
  async function report(type: string, err: any) {
    const count = updateErrorCount()
    if (shouldTopRedirect()) {
      // @MEMO: エラー発生時はログインしなおしてトップに遷移させる
      if (count < 5) {
        context.redirect('/entry')
      } else {
        context.redirect('/error')
        errorCount = 0
      }
    }

    if (dontReport(type, err)) {
      return
    }

    const frontContext = {
      uuid,
      gitCommitHash: context.$config.GIT_COMMIT_HASH,
      compiledAt: context.$config.COMPILED_AT,
      scriptLoadedAt,
      localStorage,
    }

    console.log(`Captured in ${type} : `, err)
    try {
      const response = await context.$axios.post('api/error/create', {
        type,
        content: JSON.stringify(err, null, '\t'),
        frontContext: JSON.stringify(frontContext, null, '\t'),
      })
    } catch (e) {
      console.log(e)
    }
  }

  function shouldTopRedirect() {
    // MEMO: メッセージ画面ではエラーステートを表示するので、リダイレクトさせない
    return !context.route.path.match('/topic/')
  }

  function dontReport(type: string, err: any) {
    if (
      type === 'axios' &&
      err.response?.status === 401 &&
      err.response?.responseData?.message === 'Unauthenticated.'
    ) {
      return true
    }

    return false
  }

  // @TODO: make them to specified class
  function updateErrorCount() {
    if (
      !errorOccurredAt ||
      dayjs(errorOccurredAt).isAfter(dayjs().subtract(1, 'minutes'))
    ) {
      errorCount++
    } else {
      errorCount = 1
    }
    errorOccurredAt = new Date()

    return errorCount
  }
}

function makeUUID() {
  const S4 = function () {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
  }

  return (
    S4() +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    '-' +
    S4() +
    S4() +
    S4()
  )
}
