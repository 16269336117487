import ITerm from '~/Interfaces/Message/ITerm'
import IExcerpt from '~/Interfaces/Message/IExcerpt'

export default class ConnectTerms {
  terms: ITerm[] = []

  private pageTypes: { [index: string]: string } = {
    sa_terms: 'sa_terms',
    connect_terms: 'connect_terms',
    connect_service_agreement: 'connect_service_agreement',
    connect_terms_company: 'connect_terms_company',
  }

  private excerptTypes: { [index: string]: string } = {
    contract_payment: 'contract_payment',
    contract_termination: 'contract_termination',
  }

  constructor(terms: ITerm[]) {
    this.terms = terms
  }

  /**
   * 業務委託約款
   */
  getConnectServiceAgreement(): string | null | undefined {
    return this.getHtmlByType(this.pageTypes.connect_service_agreement)
  }

  /**
   * 契約停止申請(クライアント)の規約抜粋
   */
  contractTerminationExcerpt(): IExcerpt | undefined {
    return this.getExcerptByType(
      this.pageTypes.connect_terms,
      this.excerptTypes.contract_termination
    )
  }

  /**
   * 契約停止申請(パートナー)の規約抜粋
   */
  contractPartnerTerminationExcerpt(): IExcerpt | undefined {
    return this.getExcerptByType(
      this.pageTypes.connect_terms_company,
      this.excerptTypes.contract_termination
    )
  }

  getHtmlByType(pageType: string) {
    const term = this.terms.find((term) => {
      return term.pageType === pageType
    })

    if (!term) {
      return ''
    }

    return term.html
  }

  getExcerptByType(
    pageType: string,
    excerptTypes: string
  ): IExcerpt | undefined {
    const term = this.terms.find((term) => {
      return term.pageType === pageType
    })

    if (!term) {
      return undefined
    }

    return term.excerpts.find((excerpt) => {
      return excerpt.type === excerptTypes
    })
  }
}
