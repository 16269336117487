import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'
import IThreads from '~/Interfaces/Message/IThreads'
import ITopic from '~/Interfaces/Message/ITopic'
import IConnectClient from '~/Interfaces/IConnectClient'
import ITeam from '~/Interfaces/ITeam'
import IGroups from '~/Interfaces/Message/IGroups'
import ISearchResult from '~/Interfaces/Message/ISearchResult'
import { IMyAccount } from '~/store/MyAccounts'
import IProposalDocumentGroups from '~/Interfaces/Message/IProposalDocumentGroups'

export default class MessageService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * ログインユーザーがゲストアカウントかどうか判定
   */
  async checkGuestAccount(): Promise<boolean> {
    try {
      const response = await this.axios.get(
        'api/message_login/check_guest_account'
      )
      return response.data.result
    } catch (err) {
      console.log(err)
    }

    return false
  }

  /**
   * アカウントを取得する
   */
  async getMyAccounts(): Promise<IMyAccount[] | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.get('api/message_login/my_accounts')

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.myAccounts))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * グループを取得する
   */
  async getGroups(): Promise<IGroups | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.get('api/thread/groups')

      console.log(response)

      const thread_groups = JSON.parse(
        JSON.stringify(response.data.thread_groups)
      )
      const reads = JSON.parse(JSON.stringify(response.data.reads))

      return { thread_groups, reads }
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * スレッドを取得する
   * @param thread_group_hash_id
   */
  async getThreads(
    thread_group_hash_id: string
  ): Promise<IThreads | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.get(
        'api/thread/threads/' + thread_group_hash_id
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.thread_group))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * トピックを取得する
   * @param thread_hash_id
   */
  async getTopic(thread_hash_id: string): Promise<ITopic | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.get(
        'api/thread/topic/' + thread_hash_id
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.topic))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * スレッドを作成する
   * @param proposal_hash_id
   */
  async openThread(proposal_hash_id: string): Promise<string | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post(
        'api/thread/connect_client/create_thread',
        {
          proposal_hash_id,
        }
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.threadHashId))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * メッセージを投稿する
   * @param thread_hash_id
   * @param text
   * @param file
   */
  async createMessage(
    thread_hash_id: string,
    text: string,
    file: File | null
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.message)

    const headers = { 'content-type': 'multipart/form-data' }
    const data = new FormData()
    data.append('thread_hash_id', thread_hash_id)
    data.append('text', text)
    if (file !== null) {
      data.append('file', file)
    }

    try {
      const response = await this.axios.post('api/thread/detail', data, {
        headers,
      })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * 既読にする
   * @param thread_hash_id
   */
  async postRead(thread_hash_id: string): Promise<boolean> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post(
        'api/thread/read/' + thread_hash_id
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * 検索する
   * @param text
   */
  async search(text: string): Promise<ISearchResult[] | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post('api/thread/search', {
        text,
      })

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.search_results))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  /**
   * フィルタリングする
   * @param category
   */
  async filter(category: string): Promise<ISearchResult[] | undefined> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post('api/thread/filter', {
        category,
      })

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.search_results))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  async agreeAttentionToClient(threadHashId: string): Promise<boolean> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.post(
        'api/thread/agree_attention/' + threadHashId
      )

      console.log(response)
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  async sendProposalDocument(
    proposal_hash_id: string,
    thread_hash_id: string,
    text: string,
    file: File,
    thumbnail: File | string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.message)

    const headers = { 'content-type': 'multipart/form-data' }
    const data = new FormData()
    data.append('proposal_hash_id', proposal_hash_id)
    data.append('thread_hash_id', thread_hash_id)
    data.append('message', text)
    data.append('file', file)
    data.append('thumbnail', thumbnail)

    try {
      const response = await this.axios.post(
        'api/thread/send_proposal_document',
        data,
        { headers }
      )
      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }

  async getProposalDocumentList(
    proposal_hash_id: string
  ): Promise<Array<any> | null> {
    LockStore.lock(LockStore.types.message)
    try {
      const response = await this.axios.get(
        'api/thread/proposal_document_list/' + proposal_hash_id
      )
      return JSON.parse(JSON.stringify(response.data.proposal_document_list))
    } catch (err) {
      console.log(err)
      return null
    } finally {
      LockStore.unlock(LockStore.types.message)
    }
  }
}
