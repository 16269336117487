import {NuxtAxiosInstance} from "@nuxtjs/axios";
import {LockStore} from "~/store";
import IConnectProposal from "~/Interfaces/IConnectProposal";

export default class ProposalService {
  private readonly axios: NuxtAxiosInstance;

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * 商談する
   * @param proposal_hash_id
   */
  async negotiate(proposal_hash_id: string): Promise<IConnectProposal|undefined>
  {
    LockStore.lock(LockStore.types['propose'])
    try {
      let response = await this.axios.post('api/client/proposal/negotiate/' + proposal_hash_id)

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types['propose'])
    }
  }

  /**
   * 依頼する
   * @param proposal_hash_id
   */
  async accept(proposal_hash_id: string): Promise<IConnectProposal|undefined>
  {
    LockStore.lock(LockStore.types['propose'])
    try {
      let response = await this.axios.post('api/client/proposal/accept/' + proposal_hash_id)

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types['propose'])
    }
  }

  /**
   * 見送る
   * @param proposal_hash_id
   */
  async reject(proposal_hash_id: string): Promise<IConnectProposal|undefined>
  {
    LockStore.lock(LockStore.types['propose'])
    try {
      let response = await this.axios.post('api/client/proposal/reject/' + proposal_hash_id)

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types['propose'])
    }
  }
}
