import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import IConnectContractRenewal from '../Interfaces/IConnectContractRenewal'

@Module({
  stateFactory: true,
  namespaced: true,
  name: 'ConnectContractRenewal',
})
export default class ConnectContractRenewal extends VuexModule {
  connectContractRenewal: IConnectContractRenewal | undefined = undefined

  @Mutation
  setConnectContractRenewal(
    connectContractRenewal: IConnectContractRenewal | null
  ) {
    if (
      connectContractRenewal === null ||
      !Object.keys(connectContractRenewal).length
    ) {
      this.connectContractRenewal = undefined

      return
    }

    this.connectContractRenewal = connectContractRenewal
  }

  get getConnectContractRenewal(): IConnectContractRenewal | undefined {
    return this.connectContractRenewal
  }

  get isRenewing(): boolean {
    if (this.connectContractRenewal === undefined) {
      return false
    }

    return (
      ((this.connectContractRenewal
        .connectContractRenewalStatus as any) as string) === 'negotiating'
    )
  }
}
