import { Auth } from '@nuxtjs/auth-next'

export default class UserService {
  private readonly auth: Auth
  private isGuestAccount: boolean = false

  constructor(auth: Auth) {
    this.auth = auth
  }

  /**
   * チームイメージを取得する
   */
  public getTeamImage(): string | undefined {
    return <string | undefined>this.auth.user?.team_image
  }

  public getConnectClientHashId(): string | undefined {
    return <string | undefined>this.auth.user?.connect_client_hash_id
  }

  public getIsGuestAccount(): boolean {
    return this.isGuestAccount
  }

  public setIsGuestAccount(isGuestAccount: boolean) {
    this.isGuestAccount = isGuestAccount
  }
}
