import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'

import Offset from '~/store/Offset'
import Snackbar from '~/store/Snackbar'
import Lock from '~/store/Lock'
import ThreadGroups from '~/store/ThreadGroups'
import Topic from '~/store/Topic'
import ThreadDetails from '~/store/ThreadDetails'
import MyAccounts from '~/store/MyAccounts'
import Reads from '~/store/Reads'
import SearchResult from '~/store/SearchResult'
import UploadFile from '~/store/UploadFile'
import ThreadGroup from '~/store/ThreadGroup'
import TeamMembers from '~/store/TeamMembers'
import Modal from '~/store/Modal'
import IsLoggedIn from '~/store/IsLoggedIn'
import CloudMembers from '~/store/CloudMembers'
import ConnectContractRenewal from '~/store/ConnectContractRenewal'

let OffsetStore: Offset
let SnackbarStore: Snackbar
let LockStore: Lock
let ThreadGroupsStore: ThreadGroups
let TopicStore: Topic
let ThreadDetailsStore: ThreadDetails
let MyAccountsStore: MyAccounts
let ReadsStore: Reads
let SearchResultStore: SearchResult
let UploadFileStore: UploadFile
let ThreadGroupStore: ThreadGroup
let TeamMembersStore: TeamMembers
let ModalStore: Modal
let IsLoggedInStore: IsLoggedIn
let CloudMembersStore: CloudMembers
let ConnectContractRenewalStore: ConnectContractRenewal

function initialiseStores(store: Store<any>): void {
  OffsetStore = getModule(Offset, store)
  SnackbarStore = getModule(Snackbar, store)
  LockStore = getModule(Lock, store)
  ThreadGroupsStore = getModule(ThreadGroups, store)
  TopicStore = getModule(Topic, store)
  ThreadDetailsStore = getModule(ThreadDetails, store)
  MyAccountsStore = getModule(MyAccounts, store)
  ReadsStore = getModule(Reads, store)
  SearchResultStore = getModule(SearchResult, store)
  UploadFileStore = getModule(UploadFile, store)
  ThreadGroupStore = getModule(ThreadGroup, store)
  TeamMembersStore = getModule(TeamMembers, store)
  ModalStore = getModule(Modal, store)
  IsLoggedInStore = getModule(IsLoggedIn, store)
  CloudMembersStore = getModule(CloudMembers, store)
  ConnectContractRenewalStore = getModule(ConnectContractRenewal, store)
}

export {
  initialiseStores,
  OffsetStore,
  SnackbarStore,
  LockStore,
  ThreadGroupsStore,
  TopicStore,
  ThreadDetailsStore,
  MyAccountsStore,
  ReadsStore,
  SearchResultStore,
  UploadFileStore,
  ThreadGroupStore,
  TeamMembersStore,
  ModalStore,
  IsLoggedInStore,
  CloudMembersStore,
  ConnectContractRenewalStore,
}
