import ISa from "~/Interfaces/ISa";
import {LockStore} from "~/utils/store-accessor";
import {NuxtAxiosInstance} from "@nuxtjs/axios";
import IClient from "~/Interfaces/IClient";

export default class MemberService {
  private readonly axios: NuxtAxiosInstance;

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }


  /**
   *
   */
  async getConnectCompany(): Promise<IClient[]| undefined>
  {
    LockStore.lock(LockStore.types['member'])
    try {
      let response = await this.axios.get('api/member/connect_company')

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.members));
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types['member'])
    }
  }

  /**
   *
   */
  async getCloud(): Promise<IClient[]| undefined>
  {
    LockStore.lock(LockStore.types['member'])
    try {
      let response = await this.axios.get('api/member/cloud')

      console.log(response)

      return JSON.parse(JSON.stringify(response.data.members));
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types['member'])
    }
  }
}
