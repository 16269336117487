import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'
import { LockStore } from '~/store'
import ConnectTerms from '~/components/Entities/ConnectTerm/ConnectTerms'
import IConnectClientContact from '~/Interfaces/ConnectClientContact/IConnectClientContact'
import IConnectContractRenewal from '~/Interfaces/IConnectContractRenewal'

export default class ConnectService {
  private readonly axios: NuxtAxiosInstance
  private readonly config: NuxtRuntimeConfig

  constructor(axios: NuxtAxiosInstance, config: NuxtRuntimeConfig) {
    this.axios = axios
    this.config = config
  }

  /**
   */
  async requestQuotation(thread_hash_id: string): Promise<boolean> {
    LockStore.lock(LockStore.types.connect)
    try {
      const response = await this.axios.post('api/connect/request_quotation', {
        thread_hash_id,
      })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.connect)
    }
  }

  /**
   */
  async reject(thread_hash_id: string): Promise<boolean> {
    LockStore.lock(LockStore.types.connect)
    try {
      const response = await this.axios.post('api/connect/reject', {
        thread_hash_id,
      })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.connect)
    }
  }

  /**
   * 契約停止申請
   */
  async cancellation(
    thread_hash_id: string,
    termination_date: string,
    reason: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.connect)
    try {
      const response = await this.axios.post(
        'api/connect/contract_termination',
        {
          thread_hash_id,
          termination_date,
          reason,
        },
        {
          withCredentials: true,
        }
      )

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.connect)
    }
  }

  /**
   * 規約
   */
  async getTerms(): Promise<ConnectTerms> {
    LockStore.lock(LockStore.types.connect)
    try {
      const response = await this.axios.get('api/connect/term')

      console.log(response)

      const terms = JSON.parse(JSON.stringify(response.data))
      return new ConnectTerms(terms)
    } catch (err) {
      console.log(err)
      return new ConnectTerms([])
    } finally {
      LockStore.unlock(LockStore.types.connect)
    }
  }

  /**
   * 業務委託約款に同意しているかどうか
   */
  async isClientAgreedServiceAgreement(): Promise<boolean> {
    LockStore.lock(LockStore.types.connect)
    try {
      const response = await this.axios.get(
        'api/connect/client/is_agreed_service_agreement'
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return false
    } finally {
      LockStore.unlock(LockStore.types.connect)
    }
  }

  /**
   * 業務委託約款に同意する
   */
  async makeClientAgreedServiceAgreement(): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.connect)
    try {
      const response = await this.axios.post(
        'api/connect/client/make_agreed_service_agreement'
      )

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.connect)
    }
  }

  /**
   * connectClientの連絡先を取得する
   */
  async getConnectClientContact(
    connect_proposal_hash_id: string | undefined
  ): Promise<IConnectClientContact | null> {
    LockStore.lock(LockStore.types.connect)
    try {
      if (connect_proposal_hash_id === undefined) {
        return null
      }
      const response = await this.axios.get(
        'api/connect/client/contact/' + connect_proposal_hash_id
      )

      console.log(response)
      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return null
    } finally {
      LockStore.unlock(LockStore.types.connect)
    }
  }

  /**
   * 更新
   */
  async findConnectContractRenewal(
    connectProposalHashId: string | undefined
  ): Promise<IConnectContractRenewal | null> {
    if (connectProposalHashId === null) {
      return null
    }

    LockStore.lock(LockStore.types.connect)
    try {
      const response = await this.axios.get(
        'api/connect/renew/find/' + connectProposalHashId
      )

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
      return null
    } finally {
      LockStore.unlock(LockStore.types.connect)
    }
  }
}
