import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

@Module({ stateFactory: true, namespaced: true, name: 'IsLoggedIn'})
export default class IsLoggedIn extends VuexModule {
  is_logged_in: boolean = false

  @Mutation
  set(is_logged_in: boolean)
  {
    this.is_logged_in = is_logged_in
  }

  get get() {
    return this.is_logged_in
  }
}
