import {NuxtAxiosInstance} from "@nuxtjs/axios";
import IPaging from "~/Interfaces/IPaging";
import IProduct from "~/Interfaces/IProduct";
import {LockStore} from "~/store";

export default class ProductService {
  private readonly axios: NuxtAxiosInstance;

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * ページを取得する
   * @param page
   */
  async get(page: number): Promise<IPaging|undefined>
  {
    try {
      let response = await this.axios.get('api/product/get/' + page)

      console.log(response)

      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * 1件取得する
   * @param product_hash_id
   */
  async find(product_hash_id: string): Promise<IProduct|undefined>
  {
    try {
      let response = await this.axios.get('api/product/find/' + product_hash_id)

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * 提案する
   * @param product_hash_id
   * @param proposal
   */
  async propose(product_hash_id: string, proposal: string): Promise<IProduct|undefined>
  {
    LockStore.lock(LockStore.types['propose'])
    try {
      let response = await this.axios.post('api/product/propose/' + product_hash_id, {
        proposal : proposal
      })

      // console.log(response)

      return JSON.parse(JSON.stringify(response.data));
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types['propose'])
    }
  }
}
