import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

export interface IModal {
  name: string,
  is_display: boolean
}

@Module({ stateFactory: true, namespaced: true, name: 'Modal'})
export default class Modal extends VuexModule {
  modals: IModal[] = []

  @Mutation
  show(name: string)
  {
    let index = this.modals.findIndex(snackbar => snackbar.name === name);
    if(index < 0) {
      this.modals.push({name: name, is_display: true})

      return
    }

    this.modals[index].is_display = true;
  }

  @Mutation
  hide(name: string)
  {
    let index = this.modals.findIndex(snackbar => snackbar.name === name);
    if(index === undefined) {
      return;
    }

    this.modals[index].is_display = false;
  }

  get shouldDisplay() {
    var self = this;

    return (name: string) => {
      let modal = self.modals.find(snackbar => snackbar.name === name);

      return modal?.is_display ?? false
    }
  }
}
