import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import ITopic from "../Interfaces/Message/ITopic";
import IThreadGroupAccount from "~/Interfaces/Message/IThreadGroupAccount";
import dayjs from "dayjs";

@Module({ stateFactory: true, namespaced: true, name: 'Thread'})
export default class Thread extends VuexModule {
  thread!: ITopic

  @Mutation
  setThread(thread: ITopic|undefined)
  {
    if(thread === undefined) {
      return
    }
    console.log(thread)

    this.thread = thread;

    console.log(this.thread)
  }

  get getThread(): ITopic {
    return this.thread
  }

  get getThreadGroupAccountSelf(): IThreadGroupAccount
  {
    const index = this.thread.group.threadGroupAccounts.findIndex((threadGroupAccount: IThreadGroupAccount) => {
      return threadGroupAccount.isSelf
    })

    return this.thread.group.threadGroupAccounts[index]
  }

  get getThreadGroupAccountOther(): IThreadGroupAccount
  {
    const index = this.thread.group.threadGroupAccounts.findIndex((threadGroupAccount: IThreadGroupAccount) => {
      return !threadGroupAccount.isSelf
    })

    return this.thread.group.threadGroupAccounts[index]
  }

  get getNameOther(): string|null
  {
    const account = this.getThreadGroupAccountOther
    if(account.team) {
      return account.team.teamName
    }
    if(account.connectClient) {
      return account.connectClient.connectClientCompanyName
    }

    return ''
  }

  get getFoundationDate(): string|null
  {
    const account = this.getThreadGroupAccountOther

    if(account.team) {
      if(account.team.teamFoundationDate !== null) {
        return dayjs(account.team.teamFoundationDate).format('YYYY/MM/DD')
      }
    }
    if(account.connectClient) {
      return null
    }

    return null
  }

  get getEmployeesSize(): string|null
  {
    const account = this.getThreadGroupAccountOther

    if(account.team) {
      if(account.team.teamActivePerson !== null) {
        return account.team.teamActivePerson + '人'
      }
    }
    if(account.connectClient) {
      if(account.connectClient.connectClientEmployeesSize !== null) {
        return account.connectClient.connectClientEmployeesSize
      }
    }

    return null
  }
}
