import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import IClient from "../Interfaces/IClient";

@Module({ stateFactory: true, namespaced: true, name: 'CloudMembers'})
export default class CloudMembers extends VuexModule {
  members: IClient[] = []

  @Mutation
  setMembers(members: IClient[])
  {
    this.members = members;
  }

  get getMembers(): IClient[]
  {
    return this.members
  }
}
