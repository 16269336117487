import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import IConnectClient from "../Interfaces/IConnectClient";
import ITeam from "../Interfaces/ITeam";
import ISa from "~/Interfaces/ISa";
import ICompany from "~/Interfaces/ICompany";
import ISearchResult from "~/Interfaces/Message/ISearchResult";

export interface IMyAccount {
  account: IConnectClient|ITeam|ISa|ICompany,
}

@Module({ stateFactory: true, namespaced: true, name: 'MyAccounts'})
export default class MyAccounts extends VuexModule {
  myAccounts: IMyAccount[] = []

  @Mutation
  setMyAccounts(myAccounts: IMyAccount[])
  {
    this.myAccounts = myAccounts;
  }

  get getMyAccounts(): IMyAccount[]
  {
    return this.myAccounts
  }

  get getFirst(): IMyAccount {
    return this.myAccounts[0];
  }

  get getFirstColorIndex(): number {
    const myAccount = this.myAccounts[0]

    if(myAccount === undefined) {
      return 0
    }

    return myAccount.account.colorIndex
  }

  get getName(): string {
    const myAccount = this.myAccounts[0]

    if(myAccount === undefined) {
      return ''
    }

    const account = myAccount.account
    if(MyAccounts.isIConnectClient(account)) {
      if(account.connectClientCompanyName === null) {
        return ''
      }

      return account.connectClientCompanyName
    }

    if(MyAccounts.isITeam(account)) {
      return account.teamName
    }

    if(MyAccounts.isISa(account)) {
      return account.saName
    }

    if(MyAccounts.isICompany(account)) {
      return account.companyName
    }

    return ''
  }

  get getImage(): string|null {
    const myAccount = this.myAccounts[0]

    if(myAccount === undefined) {
      return null
    }

    const account = myAccount.account

    if(MyAccounts.isIConnectClient(account)) {
      return null
    }
    if(MyAccounts.isITeam(account)) {
      return  account.teamImage === '' ? null : account.teamImage
    }
    if(MyAccounts.isISa(account)) {
      return  account.saImage === '' ? null : account.saImage
    }
    if(MyAccounts.isICompany(account)) {
      return  account.companyImage === '' ? null : account.companyImage
    }

    return null
  }

  /**
   * IConnectClientかどうか
   * @param arg
   */
  public static isIConnectClient(arg: any): arg is IConnectClient
  {
    if(arg === null){
      return false
    }

    return arg.connectClientHashId !== undefined;
  }

  /**
   * ITeamかどうか
   * @param arg
   */
  public static isITeam(arg: any): arg is ITeam
  {
    if(arg === null){
      return false
    }

    return arg.teamHashId !== undefined;
  }

  /**
   * ISaかどうか
   * @param arg
   */
  public static isISa(arg: any): arg is ISa
  {
    if(arg === null){
      return false
    }

    return arg.saHashId !== undefined;
  }

  /**
   * ICompanyかどうか
   * @param arg
   */
  public static isICompany(arg: any): arg is ICompany
  {
    if(arg === null){
      return false
    }

    return arg.companyHashId !== undefined;
  }
}
