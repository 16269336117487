import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { LockStore } from '~/store'

export default class KakutokuService {
  private readonly axios: NuxtAxiosInstance

  constructor(axios: NuxtAxiosInstance) {
    this.axios = axios
  }

  /**
   * 違反を報告する
   */
  async complaintReport(
    name: string,
    email: string,
    company_name: string,
    staff_name: string | null,
    detail: string,
    account_hash_id: string,
    account_type: string | null
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.kakutoku)
    try {
      const response = await this.axios.post('api/complaint/report', {
        name,
        email,
        company_name,
        staff_name,
        detail,
        account_hash_id,
        account_type,
      })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.kakutoku)
    }
  }

  /**
   * 辞退する
   */
  async decline(
    thread_hash_id: string,
    text: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.kakutoku)
    try {
      const response = await this.axios.post('api/thread/decline', {
        thread_hash_id,
        text,
      })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.kakutoku)
    }
  }

  /**
   * する
   */
  async cancellation(
    thread_hash_id: string,
    date: string,
    reason: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.kakutoku)
    try {
      const response = await this.axios.post('api/thread/cancellation', {
        thread_hash_id,
        date,
        reason,
      })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.kakutoku)
    }
  }
}
