import {Auth} from "@nuxtjs/auth-next";
import {LockStore} from "~/utils/store-accessor";

export default class AuthService {
  private readonly auth: Auth;

  constructor(auth: Auth) {
    this.auth = auth
  }

  /**
   * ログインする
   * @param email
   * @param password
   * @param is_client
   */
  async login(email: string, password: string, is_client: boolean): Promise<boolean>
  {
    try {
      LockStore.lock(LockStore.types['login'])

      let response = await this.auth.loginWith(is_client ? 'client' : 'company', {
        data: {email, password}
      })

      // console.log(response)

      return false;
    } catch (err) {
      console.log(err)

      LockStore.unlock(LockStore.types['login'])

      return true;
    }
  }

  /**
   * ログアウトする
   */
  async logout()
  {
    try {
      this.auth.logout()
      LockStore.unlock(LockStore.types['login'])
    } catch(err) {
      console.log(err)
    }
  }
}
