













import {Component, Vue} from "nuxt-property-decorator";

@Component
export default class Out extends Vue {

  constructor() {
    super();
  }
}
