import IThreadGroupAccount from "~/Interfaces/Message/IThreadGroupAccount";
import MyAccounts from "~/store/MyAccounts";

export default class MessageAccountService {
  public getThreadGroupAccountOther(threadGroupAccounts: IThreadGroupAccount[]): IThreadGroupAccount|undefined {
    const index = threadGroupAccounts.findIndex((threadGroupAccount: IThreadGroupAccount) => {
      return !threadGroupAccount.isSelf
    })

    return threadGroupAccounts[index]
  }

  public getThreadGroupAccountSelf(threadGroupAccounts: IThreadGroupAccount[]): IThreadGroupAccount|undefined {
    const index = threadGroupAccounts.findIndex((threadGroupAccount: IThreadGroupAccount) => {
      return threadGroupAccount.isSelf
    })

    return threadGroupAccounts[index]
  }

  /**
   *
   * @param account
   */
  public getName(account: IThreadGroupAccount|undefined): string | null {
    if(account === undefined) {
      return  ''
    }

    if (account.team) {
      return account.team.teamName
    }
    if (account.connectClient) {
      return account.connectClient.connectClientCompanyName
    }
    if(account.sa) {
      return account.sa.saName
    }
    if(account.company) {
      return account.company.companyName
    }

    return ''
  }

  /**
   *
   * @param account
   */
  public getColorIndex(account: IThreadGroupAccount|undefined): number|null
  {
    if(account === undefined) {
      return null
    }

    return account.colorIndex
  }

  /**
   *
   * @param account
   */
  public getImage(account: IThreadGroupAccount|undefined): string|null {
    if(account === undefined) {
      return null
    }
    if(MyAccounts.isIConnectClient(account.connectClient)) {
      return null
    }

    if(MyAccounts.isITeam(account.team)) {
      return  account.team.teamImage === '' ? null : account.team.teamImage
    }
    if(MyAccounts.isISa(account.sa)) {
      return  account.sa.saImage === '' ? null : account.sa.saImage
    }

    if(MyAccounts.isICompany(account.company)) {
      return  account.company.companyImage === '' ? null : account.company.companyImage
    }

    return null
  }
}
