import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import ISearchResult from "../Interfaces/Message/ISearchResult";

@Module({ stateFactory: true, namespaced: true, name: 'SearchResult'})
export default class SearchResult extends VuexModule {
  searchResults: ISearchResult[] = []
  is_searched: boolean = false

  @Mutation
  setSearchResult(searchResults: ISearchResult[]|undefined)
  {
    if(searchResults === undefined) {
      return
    }

    this.searchResults = searchResults;
  }

  get getSearchResults(): ISearchResult[]
  {
    return this.searchResults
  }

  @Mutation
  setIsSearched(is_searched: boolean)
  {
    this.is_searched = is_searched;
  }

  get getIsSearched(): boolean
  {
    return this.is_searched
  }
}
