import { NuxtAxiosInstance } from '@nuxtjs/axios'
import { NuxtRuntimeConfig } from '@nuxt/types/config/runtime'
import { LockStore } from '~/store'
import ISa from '~/Interfaces/Profile/ISa'
import IProposal from '~/Interfaces/Profile/IProposal'

export default class CloudService {
  private readonly axios: NuxtAxiosInstance
  private readonly config: NuxtRuntimeConfig

  constructor(axios: NuxtAxiosInstance, config: NuxtRuntimeConfig) {
    this.axios = axios
    this.config = config
  }

  /**
   * 違反を報告する
   */
  async complaintReport(
    name: string,
    email: string,
    company_name: string,
    staff_name: string | null,
    detail: string,
    sa_hash_id: string,
    account_type: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.kakutoku)
    try {
      const response = await this.axios.post('api/complaint/report', {
        name,
        email,
        company_name,
        staff_name,
        detail,
        sa_hash_id,
        account_type,
      })

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.kakutoku)
    }
  }

  /**
   * 辞退する
   */
  async decline(
    proposal_hash_id: string,
    reject_reasons: string[],
    screening_other_reason: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.kakutoku)
    try {
      const response = await this.axios.post(
        this.config.APP_URL_CLOUD +
          'api/message/proposal/fail_by_interview/' +
          proposal_hash_id,
        {
          reject_reasons,
          screening_other_reason,
        },
        {
          withCredentials: true,
        }
      )

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.kakutoku)
    }
  }

  /**
   * 契約を解除する
   */
  async cancellation(
    termination_contract_proposal_hash_id: string,
    termination_contract_termination_date: string,
    termination_contract_reason: string,
    contract_hash_id: string
  ): Promise<boolean | undefined> {
    LockStore.lock(LockStore.types.kakutoku)
    try {
      const response = await this.axios.post(
        this.config.APP_URL_CLOUD + 'api/message/contract/termination',
        {
          termination_contract_proposal_hash_id,
          termination_contract_termination_date,
          termination_contract_reason,
          contract_hash_id,
        },
        {
          withCredentials: true,
        }
      )

      console.log(response)

      return true
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.kakutoku)
    }
  }

  /**
   * SAを取得する
   */
  async findProfile(
    sa_hash_id: string,
    proposal_hash_id: string | null | undefined
  ): Promise<{ sa: ISa; proposal: IProposal } | undefined> {
    let url = 'api/cloud/sa/profile/' + sa_hash_id
    if (proposal_hash_id) {
      url = url + '/' + proposal_hash_id
    }

    LockStore.lock(LockStore.types.kakutoku)
    try {
      const response = await this.axios.get(url)

      console.log(response)

      return JSON.parse(JSON.stringify(response.data))
    } catch (err) {
      console.log(err)
    } finally {
      LockStore.unlock(LockStore.types.kakutoku)
    }
  }
}
