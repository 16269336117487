import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'

export interface IUploadFile {
  is_opened: boolean,
  file: File|null
}

@Module({ stateFactory: true, namespaced: true, name: 'UploadFile'})
export default class UploadFile extends VuexModule {
  upload: IUploadFile = {is_opened: false, file: null}

  @Mutation
  setIsOpen(is_opened: boolean)
  {
    this.upload.is_opened = is_opened
  }


  @Mutation
  setFile(file: File|null)
  {
    this.upload.file = file
  }

  get getIsOpened(): boolean
  {
    return this.upload.is_opened;
  }

  get getFile(): File|null
  {
    return this.upload.file
  }

}
